import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  scrollToElementById(id: string): any {
    const element = this.getElementById(id);
    this.scrollToElement(element);
  }

  private getElementById(id: string): any {
    const element = this.document.querySelector(`${id}`);
    return element;
  }

  scrollToElement(element: HTMLElement): void {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
