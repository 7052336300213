import { Injectable } from '@angular/core';
import { ButterService } from '@core/services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LegalMenuItemDTO, MenuItem } from '@app/layout/models/menu-item.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private butter: ButterService) {}

  public getMenuItems(): Observable<MenuItem[]> {
    return this.butter.collection('teqball_header').pipe(map((result) => result.data.teqball_header));
  }

  public getFooterItems(): Observable<MenuItem[]> {
    return this.butter
      .collection('teqball_footer', {
        'fields.root': true,
        levels: 2,
      })
      .pipe(map((result) => this.menuAdapter(result.data.teqball_footer)));
  }

  public getAdditionalMenuItems(): Observable<LegalMenuItemDTO[]> {
    return this.butter.collection('teqball_legal_menu').pipe(map((result) => result.data.teqball_legal_menu));
  }

  private menuAdapter(menu: MenuItem[], filtering = false, sorting = true): MenuItem[] {
    if (sorting) menu = menu.sort((a, b) => a.order - b.order);

    return menu.map((item) => {
      if (item.children) {
        item.children = this.menuAdapter(item.children, filtering, sorting);
      }
      return item;
    });
  }
}
